import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
    })
  };

  constructor( private httpClient: HttpClient ) { }

  getResources(cpf, vehicleFilter = null, showMorePages = 0): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'cpflocator': cpf
      })
    };
    
    let url = `${environment.officeApi}/resources/list?show_more_pages=${showMorePages}&search=${vehicleFilter || ''}`
    
    return this.httpClient.get(url, httpOptions).pipe(
      catchError(err => {
        alert('Não foi possível obter os veículos deste chat, recarregue a página ou entre em contato com nosso suporte')
        console.log(err)

        return err
      })
    )
  }

  getIdentifier(resourceId): Observable<any> {
    let url = `${environment.officeApi}/get-identifier/${resourceId}`

    return this.httpClient.get(url, this.httpOptions)
  }
}
